











import { Component, Vue } from "vue-property-decorator";
import OthersNotificationList from "@/components/Others/NotificationList.vue";

@Component({
    metaInfo() {
        const siteName = "eCSR";
        const template = " - Kelola CSR anda dengan satu aplikasi";
        const title = siteName;
        const description =
            "Mempermudah urusan CSR baik untuk perusahaan maupun organisasi/masyarakat.";

        return {
            title: title,
            titleTemplate: "%s" + template,
            meta: [
                {
                    name: "description",
                    content: description,
                },
                {
                    property: "og:title",
                    content: title,
                },
                { property: "og:site_name", content: siteName },
                { property: "og:type", content: "website" },
                { name: "robots", content: "index,follow" },
            ],
        };
    },
    components: {
        OthersNotificationList,
    },
})
export default class App extends Vue {
    mounted() {
        // console.log('Mode:', process.env.NODE_ENV)
    }
}
