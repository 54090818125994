const useQueryPrefix = (queryCount: number) => {
    let queryPrefix: string = '?'
    if (queryCount > 0) queryPrefix = '&'

    return queryPrefix
}

export const useQuery = (options: any) => {
    let query: string = ''
    let queryCount: number = 0

    for (const property in options) {
        query += useQueryPrefix(queryCount) + `${property}=${options[property]}`
        queryCount++
    }   

    return query
}


// let { take, page, type, user } = options
// let query: string = ''
// let queryCount: number = 0
// const useQueryPrefix = () => {
//     let queryPrefix: string = '?'
//     if (queryCount > 0) queryPrefix = '&'
//     queryCount++

//     return queryPrefix
// }

// if (take) query+= useQueryPrefix() + 'take=' + take
// if (page) query+= useQueryPrefix() + 'page=' + page
// if (type) query+= useQueryPrefix() + 'type=' + type
// if (user) query+= useQueryPrefix() + 'user=' + user