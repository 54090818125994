export const PRODUCTION_MODE = process.env.NODE_ENV === "production";

export const COOKIE_NAME = PRODUCTION_MODE ? process.env.VUE_APP_JWT_TOKEN : "qid";
export const SERVER_HOST = PRODUCTION_MODE ? process.env.VUE_APP_SERVER_HOST : "127.0.0.1";
export const SERVER_PORT = PRODUCTION_MODE ? process.env.VUE_APP_SERVER_PORT : 4000;
export const WEB_HOST = PRODUCTION_MODE ? process.env.VUE_APP_WEB_HOST : "127.0.0.1";
export const WEB_PORT = PRODUCTION_MODE ? process.env.VUE_APP_WEB_PORT : 8080;
export const API_END_POINT = PRODUCTION_MODE ? process.env.VUE_APP_END_POINT : "";
export const SITE_URL = "ecsr.banjarmasinkota.go.id"

// export const STORAGE_DIR = PRODUCTION_MODE ? '..' + process.env.BASE_URL + 'storage/' : process.env.BASE_URL + 'storagez/';
export const STORAGE_DIR = PRODUCTION_MODE ? '../..' + process.env.BASE_URL + 'storage/' : process.env.BASE_URL + 'storagez/';

//D:/_www/DISKOMINFOTIK/ecsr_dinsos/web