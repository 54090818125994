import vuexErrorHandler from "@/helpers/vuexErrorHandler";
import FundsService from "@/services/FundsService";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
class FundsModule extends VuexModule {
    public funds: any[] = [];
    public userUnconfirmedFunds: any[] = [];

    @Mutation
    public SET_FUNDS(funds: any[]): void {
        this.funds = funds;
    }

    @Mutation
    public SET_USER_UNCONFIRMED_FUNDS(userUnconfirmedFunds: any[]): void {
        this.userUnconfirmedFunds = userUnconfirmedFunds;
    }

    @Action
    public async getAllFundByUser(payload: any): Promise<void> {
        const { user, year } = payload;
        const queries: any[] = [];

        if (user) queries.push({ field: "user", value: user });
        if (year) queries.push({ field: "year", value: year });

        try {
            const response = await FundsService.getAllFund(queries);
            const { funds } = response.data;

            this.context.commit("SET_FUNDS", funds);
            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }
    
    @Action
    public async getUserUnconfirmedFunds(userId: string) {
        try {
            const response = await FundsService.getUserUnconfirmedFunds(userId)
            const hasData = response && response.data

            if (hasData) this.context.commit('SET_USER_UNCONFIRMED_FUNDS', response.data.funds)
            
            
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async confirmFund(fundId: string) {
        try {
            const response = await FundsService.confirmFund(fundId)
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }
}

export default FundsModule;
