










import { Component, Vue } from "vue-property-decorator";
import OthersNotificationListItem from "./NotificationListItem.vue";

@Component({
    components: {
        OthersNotificationListItem,
    },
})
export default class OthersNotificationList extends Vue {
    get notifications() {
        return this.$store.state.meta.notifications;
    }

    // mounted() {
    //     this.$store.dispatch("meta/addNotification", {
    //         message:
    //             "Test aja, Test aja, Test aja, Test aja, Test aja, Test aja, Test aja, Test aja, ",
    //     });
    // }
}
