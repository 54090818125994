import { decodeToken } from './helpers/DecodeToken';
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueCookies from "vue-cookies";
import { COOKIE_NAME } from "@/constants";
import VueMeta from 'vue-meta';

Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.use(VueMeta)

Vue.$cookies.config("-1", "", "", true);

let app: any;
let accessToken: string | null = Vue.$cookies.get(COOKIE_NAME);

if (!app) {
    app = new Vue({
        router,
        store,
        vuetify,
        render: (h) => h(App),
    }).$mount("#app");

    Vue.prototype.$isMobile = vuetify.framework.breakpoint.xs;
    Vue.prototype.$useGutter = vuetify.framework.breakpoint.mdAndUp;
    
}

// console.log(process.env.BASE_URL);


if (accessToken) {
    const decoded: any = decodeToken(accessToken)

    store.dispatch("users/getMe", decoded.userId);
    store.dispatch("notifications/getAllNotification");
}
