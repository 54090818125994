import http from "../http-common";

const END_POINT: string = "/types";

class TypesService {
    addType(typeName: string) {
        let body: any = { name: typeName };

        return http.post(`${END_POINT}`, body);
    }

    getAllTypes() {
        return http.get(`${END_POINT}`);
    }
}

export default new TypesService();
