import Vue from "vue";
import store from "@/store";
import VueRouter, { RouteConfig } from "vue-router";
import { COOKIE_NAME } from "@/constants";
import { decodeToken } from "@/helpers/DecodeToken";
import NProgress from "nprogress"

Vue.use(VueRouter);

const lazyload = (path: string) => {
    return () => import(`../${path}.vue`);
};

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "Web",
        component: lazyload("layouts/Web"),
        children: [
            {
                path: "",
                name: "Main",
                component: lazyload("views/Web/Main"),
            },
            {
                path: "perusahaan",
                name: "Companies",
                component: lazyload("views/Web/Companies"),
            },
            {
                path: "perusahaan/:companyId",
                name: "Company",
                props: true,
                component: lazyload("views/Web/Company"),
            },
            {
                path: "panduan",
                name: "Guides",
                component: lazyload("views/Web/Guides"),
            },
        ],
    },
    {
        path: "/daftar",
        name: "Register",
        component: lazyload("views/Register"),
    },
    {
        path: "/masuk",
        name: "Login",
        component: lazyload("views/Login"),
        async beforeEnter(to, from, next) {
            await isAuthenticated(to, from, next)
        },
    },
    {
        path: "/app",
        name: "App",
        component: lazyload("layouts/App"),
        async beforeEnter(to, from, next) {
            await checkAuth(to, from, next)
        },
        children: [
            {
                path: "",
                name: "Dashboard",
                component: lazyload("views/App/_dashboard"),
            },
            {
                path: "user",
                name: "Users",
                component: lazyload("views/App/Admin/Users"),
            },
            {
                path: "notifikasi",
                name: "Notifications",
                component: lazyload("views/App/_notifications"),
            },
            {
                path: "pengaturan",
                name: "Settings",
                component: lazyload("views/App/_settings"),
            },
            {
                path: "proposal",
                name: "Proposals",
                component: lazyload("views/App/_proposals"),
            },
            {
                path: "proposal/buat",
                name: "ProposalNew",
                component: lazyload("views/App/_proposalNew"),
            },
            {
                path: "proposal/:proposalId",
                name: "ProposalId",
                props: true,
                component: lazyload("views/App/_proposalId"),
            },
            {
                path: "pengusul/:proposerId",
                name: "ProposerId",
                props: true,
                component: lazyload("views/App/_proposerId"),
            },
            {
                path: "perusahaan",
                name: "Companies",
                component: lazyload("views/App/_companies"),
            },
            {
                path: "perusahaan/:companyId",
                name: "Companies",
                props: true,
                component: lazyload("views/App/_companyId"),
            },
            {
                path: "rencana",
                name: "Plans",
                component: lazyload("views/App/_plans"),
            },
            {
                path: "galeri",
                name: "Galleries",
                component: lazyload("views/App/_galleries"),
            },
            {
                path: "rencana/buat",
                name: "PlanNew",
                component: lazyload("views/App/_planNew"),
            },
            {
                path: "rencana/:planId/edit",
                name: "PlanEdit",
                props: true,
                component: lazyload("views/App/_planEdit"),
            },
            {
                path: "setup",
                name: "Setup",
                component: lazyload("views/App/_setup"),
            },
        ],
    },
];

const checkAuth = async (to: any, from: any, next: any) => {
    let user: any = (store as any).state.users.me
    if (!!user) next()
    const accessToken: string = Vue.$cookies.get(COOKIE_NAME);

    try {
        if (accessToken) {
            const decodedAccessToken: any = decodeToken(accessToken)
            user =  await store.dispatch('users/getMe', decodedAccessToken.userId)
            
            next()
        }
            
        // next({
        //     name: "Login", // back to safety route //
        //     query: { redirectFrom: to.fullPath }
        // })
    } catch (e) {
        next({
            name: "Login", // back to safety route //
            query: { redirectFrom: to.fullPath }
        })
    }
}

const isAuthenticated = async (to: any, from: any, next: any) => {
    let user: any = (store as any).state.users.me
    if (user) next({ name: 'App' })
    
    const accessToken: string = Vue.$cookies.get(COOKIE_NAME);
    if (accessToken && !user) {
        const decodedAccessToken: any = decodeToken(accessToken)
        user = await store.dispatch('users/getMe', decodedAccessToken.userId)
        
        if (user) next({ name: 'App' })
        else next()
    }

    if (!user) next()
    if (user && !!user.profile) next({ name: 'App' })
    if (user && !user.profile) next({ name: 'Settings' })    

    // const accessToken: string = Vue.$cookies.get(COOKIE_NAME);

    // try {
    //     if (accessToken) {
    //         const decodedAccessToken: any = decodeToken(accessToken)
    //         user =  await store.dispatch('users/getMe', decodedAccessToken.userId)
    //         console.log(user);
    //         // alert('Woke')
            
    //         next({ name: 'App'})
    //     }
            
    //     // next({
    //     //     name: "Login", // back to safety route //
    //     //     query: { redirectFrom: to.fullPath }
    //     // })
    // } catch (e) {
    //     next({
    //         name: "Login", // back to safety route //
    //         query: { redirectFrom: to.fullPath }
    //     })
    // }
}

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    NProgress.start()
    next()
})
router.afterEach(() => {
    NProgress.done()
})

export default router;
