import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const importModules = (modules: string[]) => {
    let importedModules: any = {};

    modules.map((module) => {
        const moduleName = module.toLowerCase();
        const moduleFile = require(`./modules/${module}Module`);

        importedModules[moduleName] = moduleFile.default;
    });

    return importedModules;
};

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: importModules([
        "Users",
        "Proposals",
        "Categories",
        "Types",
        "Notifications",
        "Roles",
        "Plans",
        "Meta",
        "Galleries",
        "Statistics",
        "Funds",
        "Cards",
        "Reports",
        "Stories",
        "Elements",
        "Sponsors",
    ]),
});
