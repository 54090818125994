import http from "../http-common";

const END_POINT: string = "statistics";

class StatisticsService {
    getCompanyStatistics(userId: string, year: number) {
        return http.get(`${END_POINT}?user=${userId}&year=${year}`);
    }

    getTopGalleries(year: number) {
        return http.get(`${END_POINT}/galleries/?year=${year}`);
    }

    getAdminDashboard(year: number = new Date().getFullYear()) {
        return http.get(`${END_POINT}/admin/dashboard/?year=${year}`)
    }

    getLandingStatistics() {
        return http.get(`${END_POINT}/landing`)
    }

    getTopCompanies(year: number = new Date().getFullYear()) {
        return http.get(`${END_POINT}/companies?year=${year}`)
    }
}

export default new StatisticsService();
