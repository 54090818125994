import vuexErrorHandler from "@/helpers/vuexErrorHandler";
import CategoriesService from "@/services/CategoriesService";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
class CategoriesModule extends VuexModule {
    public categories: any[] = [];

    get sortedCategories() {
        let sortedCategories: any = this.categories.sort((a: any, b: any) => {
            return a.name.localeCompare(b.name);
        });

        return sortedCategories;
    }

    @Mutation
    public SET_CATEGORIES(categories: any[]): void {
        this.categories = categories;
    }

    @Action
    public async getAllCategories(): Promise<any> {
        try {
            const response = await CategoriesService.getAllCategories();
            const { categories } = response.data;

            this.context.commit("SET_CATEGORIES", categories);
            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async addCategory(categoryName: string): Promise<any> {
        try {
            const response = await CategoriesService.addCategory(categoryName);
            const { category } = response.data;

            const updatedCategories: any[] = [...this.categories];
            updatedCategories.push(category);
            this.context.commit("SET_CATEGORIES", updatedCategories);

            const notification = {
                message: `Kategori <strong>${categoryName}</strong> ditambahkan.`,
            };
            this.context.dispatch("meta/addNotification", notification, {
                root: true,
            });

            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }
}

export default CategoriesModule;
