import vuexErrorHandler from "@/helpers/vuexErrorHandler";
import PlansService from "@/services/PlansService";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
class PlansModule extends VuexModule {
    public plans: any[] = [];
    public currentYearPlans: any[] = [];
    public plan: any = null;
    public dashboardPlans: any[] = [];

    get sortedPlans() {
        let sortedPlans: any = this.plans.sort((a: any, b: any) => {
            return b.created_at.localeCompare(a.created_at);
        });

        return sortedPlans;
    }

    @Mutation
    public SET_CURRENT_YEAR_PLANS(plans: any[]): void {
        this.currentYearPlans = plans;
    }

    @Mutation
    public SET_PLANS(plans: any[]): void {
        this.plans = plans;
    }

    @Mutation
    public SET_PLAN(plan: any[]): void {
        this.plan = plan;
    }

    @Mutation
    public SET_DASHBOARD_PLANS(plans: any[]): void {
        this.dashboardPlans = plans;
    }

    @Action
    public async getDashboardPlan(payload: any): Promise<any> {
        const { userId, year } = payload;
        try {
            const response = await PlansService.getDashboardPlan(userId, year);
            const { plans } = response.data;

            this.context.commit("SET_DASHBOARD_PLANS", plans);
            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async getAllPlan(): Promise<any> {
        try {
            const response = await PlansService.getAllPlan();
            const { plans } = response.data;

            this.context.commit("SET_PLANS", plans);
            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async getCurrentYearPlans(year: string | number): Promise<any> {
        try {
            const response = await PlansService.getCurrentYearPlans(year);
            
            const { plans } = response.data;

            if (plans && plans.length) this.context.commit("SET_CURRENT_YEAR_PLANS", plans);
            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async getUserPlans(userId: string): Promise<any> {
        try {
            const response = await PlansService.getUserPlans(userId);
            const { plans } = response.data;

            this.context.commit("SET_PLANS", plans);
            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async getOnePlan(planId: string): Promise<any> {
        try {
            const response = await PlansService.getOnePlan(planId);
            const { plan } = response.data;

            this.context.commit("SET_PLAN", plan);
            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async addPlan(body: any): Promise<any> {
        try {
            const response = await PlansService.addPlan(body);
            const { plan } = response.data;

            const updatedPlans: any[] = [...this.plans];
            updatedPlans.push(plan);
            this.context.commit("SET_PLANS", updatedPlans);

            const notification = {
                message: `Rencana tahun <strong>${plan.year}</strong> ditambahkan.`,
            };
            this.context.dispatch("meta/addNotification", notification, {
                root: true,
            });

            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async updatePlan(body: any): Promise<any> {
        try {
            const response = await PlansService.updatePlan(body);
            const { plan } = response.data;

            this.context.dispatch("getAllPlan");

            const notification = {
                message: `Rencana tahun <strong>${plan.year}</strong> diperbarui.`,
            };
            this.context.dispatch("meta/addNotification", notification, {
                root: true,
            });

            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }
}

export default PlansModule;
