import vuexErrorHandler from "@/helpers/vuexErrorHandler";
import GalleriesService from "@/services/GalleriesService";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
class GalleriesModule extends VuexModule {
    public galleryByUserId: any[] = [];

    @Mutation
    public SET_GALLERY_BY_USER_ID(galleryByUserId: any[]): void {
        this.galleryByUserId = galleryByUserId;
    }

    @Action
    public async getGalleryByUserId(userId: string): Promise<any> {
        try {
            const response = await GalleriesService.getGalleryByUserId(userId);
            const { gallery } = response.data;

            this.context.commit("SET_GALLERY_BY_USER_ID", gallery);
            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async addGallery(gallery: any): Promise<any> {
        try {
            let formData = new FormData();

            for (const key in gallery) {
                if (gallery[key])
                    formData.append(key, gallery[key].id || gallery[key]);
            }

            const response = await GalleriesService.addGallery(formData);

            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async updateGallery(payload: any): Promise<any> {
        const { galleryData, galleryId } = payload;
        try {
            let formData = new FormData();

            for (const key in galleryData) {
                if (galleryData[key])
                    formData.append(
                        key,
                        galleryData[key].id || galleryData[key]
                    );
            }

            const response = await GalleriesService.updateGallery(
                galleryId,
                formData
            );

            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }
}

export default GalleriesModule;
