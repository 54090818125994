import http from "../http-common";

const END_POINT: string = "stories";

class StoriesService {
    getProposalStories(proposalId: string) {
        return http.get(`proposals/${proposalId}/stories`)
    }

    addStory(story: any) {
        return http.post(END_POINT, story)
    }

    updateStory(story: any) {
        const { id, ...updateStory } = story

        return http.put(END_POINT + '/' + id, updateStory)
    }

    deleteStory(storyId: string) {
        return http.delete(END_POINT + '/' + storyId)
    }
}

export default new StoriesService();
