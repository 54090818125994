
import http from "../http-common";

const END_POINT: string = "/funds";

class FundsService {
    getAllFund(queries: any) {
        let isFirstQuery: boolean = true;
        let query: string = "";

        queries.map((q: any) => {
            let prefix: string = "&";

            if (isFirstQuery) (prefix = "?"), (isFirstQuery = false);

            query += prefix + q.field + "=" + q.value;
        });

        return http.get(`${END_POINT}${query}`);
    }

    getUserUnconfirmedFunds(userId: string) {
        return http.get(`users/${userId}/funds?is_confirmed=0`)
    }

    confirmFund(fundId: string) {
        return http.put(`${END_POINT}/${fundId}/confirm`)
    }
}

export default new FundsService();
