import { toCurrency } from "@/helpers/Formatter";
import ProposalService from "@/services/ProposalService";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
class ProposalsModule extends VuexModule {
    public proposals: any[] = [];
    public proposal: any = null;

    @Mutation
    public SET_PROPOSALS(proposals: any[]): void {
        this.proposals = proposals;
    }

    @Mutation
    public SET_PROPOSAL(proposal: any): void {
        this.proposal = proposal;
    }

    @Mutation
    public CLEAR_PROPOSAL(): void {
        this.proposal = null;
    }

    @Action
    public async addProposal(proposal: any): Promise<any> {
        try {
            let formData = new FormData();

            for (const key in proposal) {
                if (proposal[key])
                    formData.append(key, proposal[key].id || proposal[key]);
            }

            // for (var pair of formData.entries()) {
            //     console.log(pair[0] + ", " + pair[1]);
            // }

            const response = await ProposalService.addProposal(formData);
            console.log(response);
            const proposalTypeId = response.data.proposal.type
            const applicationProposalTypeId = "24e66790-42d6-4d4f-960a-b85ea4e91e43"
            const isProposalTypeApplication = proposalTypeId === applicationProposalTypeId

            if (isProposalTypeApplication) return response.data;
            
            // create given fund if proposal type is not application
            const proposalId = response.data.proposal.id
            const givenFund = proposal.required_fund

            this.context.dispatch("addFund", { proposalId, givenFund })

            return response.data;
        } catch (err) {
            const hasResponse = err && err.response

            if (!hasResponse) return err

            console.error(err.response);

            this.context.dispatch("meta/errorHandler", err.response, {
                root: true,
            });

            return err.response.data;
        }
    }

    @Action
    public async getAllProposalApplication() {
        const sortedProposals: any[] = []
        try {
            const response = await ProposalService.getAllProposalApplication();  
            const proposals = response.data.proposals



            proposals.map((proposal: any) => {
                sortedProposals.push(proposal)
            });

            this.context.commit("SET_PROPOSALS", sortedProposals);

            this.proposals.map((proposal: any) => {
                console.log(proposal.created_at);
            });

            return sortedProposals;
            
            // const createDummy = () => {
            //     const array: any[] = [];
            //     let limit = new Array(5).fill(0);
            //     let indexCounter: number = 1;
        
            //     limit.map((o: any) => {
            //         let currentDate = new Date();
            //         let created_at = currentDate.setDate(
            //             currentDate.getDate() + indexCounter
            //         );
        
            //         let object: any = {
            //             title: indexCounter,
            //             index: indexCounter,
            //             created_at: new Date(created_at),
            //         };
        
            //         indexCounter++;
            //         array.push(object);
            //     });
        
            //     return array;
            // }

            // const dummies = createDummy();
            // dummies.forEach((dummy: any) => {
            //     console.log(new Date(dummy.created_at));
            // });

            // this.context.commit("SET_PROPOSALS", dummies);

            // return dummies
        } catch (err) {
            console.error(err.response.data);

            this.context.dispatch("meta/errorHandler", err.response, {
                root: true,
            });

            return err.response.data;
        }
    }

    @Action
    public async getOneProposal(proposalId: string): Promise<any> {
        try {
            this.context.commit("CLEAR_PROPOSAL");
            const response = await ProposalService.getOneProposal(proposalId);
            this.context.commit("SET_PROPOSAL", response.data.proposal);

            return response.data;
        } catch (err) {
            console.error(err.response.data);

            this.context.dispatch("meta/errorHandler", err.response, {
                root: true,
            });

            return err.response.data;
        }
    }

    @Action
    public async addFund(payload: any): Promise<any> {
        try {
            const { proposalId, givenFund } = payload;
            await ProposalService.addFund(proposalId, givenFund);

            const proposal = await this.context.dispatch(
                "getOneProposal",
                proposalId
            );

            const notification = {
                message: `Dana CSR diberikan sebesar <strong>${toCurrency(
                    givenFund
                )}</strong>.`,
            };
            this.context.dispatch("meta/addNotification", notification, {
                root: true,
            });

            const notificationBody: any = {
                description: `Proposal <strong>${
                    proposal.proposal.title
                }</strong> mendapat dana sebesar <strong>${toCurrency(
                    givenFund
                )}</strong>`,
                route: "/proposal/" + proposal.proposal.id,
                receiverId: proposal.proposal.user.id,
            };

            const addNotification = await this.context.dispatch(
                "notifications/addNotification",
                notificationBody,
                { root: true }
            );

            const userId = proposal.proposal.user.id;
            const message = {
                title: `eCSR${
                    proposal.proposal.title
                } mendapat dana sebesar ${toCurrency(givenFund)}`,
                body: `Selamat! proposal ${
                    proposal.proposal.title
                } mendapat dana sebesar ${toCurrency(givenFund)}`,
                click_action:
                    "https://ecsr.banjarmasinkota.go.id/app/proposal/" +
                    proposal.proposal.id,
            };

            const pushMessage = { userId, message };
            console.log(pushMessage);

            await this.context.dispatch("users/sendMessage", pushMessage, {
                root: true,
            });

            return proposal;
        } catch (err) {
            console.error(err);

            this.context.dispatch("meta/errorHandler", err.response, {
                root: true,
            });

            return err.response.data;
        }
    }

    @Action
    public async addSeen(proposalId: string): Promise<any> {
        try {
            await ProposalService.addSeen(proposalId);

            const proposal = await this.context.dispatch(
                "getOneProposal",
                proposalId
            );

            return proposal;
        } catch (err) {
            console.error(err.response);

            this.context.dispatch("meta/errorHandler", err.response, {
                root: true,
            });

            return err.response.data;
        }
    }
}

export default ProposalsModule;
