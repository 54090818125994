import vuexErrorHandler from "@/helpers/vuexErrorHandler";
import ReportsService from "@/services/ReportsService";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
class ReportsModule extends VuexModule {
    public reports: any[] = [];

    @Mutation
    public SET_REPORTS(reports: any[]): void {
        this.reports = reports;
    }

    @Action
    public async getProposalReports(proposalId: string): Promise<void> {
        try {
            const response = await ReportsService.getProposalReports(proposalId);
            const { reports } = response.data;

            this.context.commit("SET_REPORTS", reports);
            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async addReport(payload: any) {
        const { proposalId, report } = payload
        let formData = new FormData();

        for (const key in report) {
            if (report[key])
                formData.append(key, report[key].id || report[key]);
        }        

        try {
            const response = await ReportsService.addReport(proposalId, formData)
            const hasResponse = response && response.data && response.data.report
            
            const notification = {
                message: `Laporan <strong>${report.description}</strong> berhasil ditambah.`,
            };
            this.context.dispatch("meta/addNotification", notification, {
                root: true,
            });

            if (hasResponse) this.context.commit("SET_REPORTS", [response.data.report, ...this.reports])
            
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }
    
    @Action
    public async deleteReport(report: any) {
        try {
            const response = await ReportsService.deleteReport(report.id)
            const notification = {
                message: `Laporan <strong>${report.description}</strong> berhasil dihapus.`,
            };
            this.context.dispatch("meta/addNotification", notification, {
                root: true,
            });
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }
}

export default ReportsModule;
