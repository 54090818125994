import { removeItem, updateItem } from "@/helpers/arrayHelper";
import vuexErrorHandler from "@/helpers/vuexErrorHandler";
import SponsorsService from "@/services/SponsorsService";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
class SponsorsModule extends VuexModule {
    public sponsors: any[] = [];

    @Mutation
    public SET_SPONSORS(sponsors: any[]): void {
        this.sponsors = sponsors;
    }

    @Action
    public async getSponsors(userId: string): Promise<void> {
        try {
            const response = await SponsorsService.getSponsors();
            const { sponsors } = response.data;            
            
            this.context.commit("SET_SPONSORS", sponsors);
            
            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async addSponsor(sponsor: any) {
        let formData = new FormData();

        for (const key in sponsor) {
            if (sponsor[key])
                formData.append(key, sponsor[key].id || sponsor[key]);
        }

        try {
            const response = await SponsorsService.addSponsor(formData)
            const notification = {
                message: `Sponsor <strong>${sponsor.name}</strong> berhasil ditambahkan.`,
            };
            this.context.dispatch("meta/addNotification", notification, {
                root: true,
            });

            console.log(response);
            

            this.context.commit("SET_SPONSORS", [response.data.sponsor, ...this.sponsors]);

            return response
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async updateSponsor(sponsor: any) {
        let formData = new FormData();

        for (const key in sponsor) {
            if (sponsor[key])
                formData.append(key, sponsor[key].id || sponsor[key]);
        }

        try {
            const response = await SponsorsService.updateSponsor(sponsor.id, formData)
            const updatedSponsors = updateItem(this.sponsors, response.data.sponsor)
            this.context.commit('SET_SPONSORS', updatedSponsors)
            
            const notification = {
                message: `Sponsor <strong>${sponsor.name}</strong> berhasil diedit.`,
            };
            this.context.dispatch("meta/addNotification", notification, {
                root: true,
            });
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async deleteSponsor(sponsor: any) {
        try {
            const response = await SponsorsService.deleteSponsor(sponsor.id)
            const updatedSponsors: any[] = removeItem([...this.sponsors], sponsor.id)
            this.context.commit('SET_SPONSORS', updatedSponsors)
            
            const notification = {
                message: `Sponsor <strong>${sponsor.name}</strong> berhasil dihapus.`,
            };
            this.context.dispatch("meta/addNotification", notification, {
                root: true,
            });

        } catch (err) {
            return vuexErrorHandler(err)
        }
    }
}

export default SponsorsModule;
