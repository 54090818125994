import http from "../http-common";

const END_POINT: string = "/categories";

class CategoriesService {
    addCategory(categoryName: string) {
        let body: any = { name: categoryName };

        return http.post(`${END_POINT}`, body);
    }

    getAllCategories() {
        return http.get(`${END_POINT}`)
    }
}

export default new CategoriesService();
