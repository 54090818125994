import http from "../http-common";

const END_POINT: string = "/galleries";

class GalleriesService {
    // addCategory(categoryName: string) {
    //     let body: any = { name: categoryName };

    //     return http.post(`${END_POINT}`, body);
    // }

    getGalleryByUserId(userId: string) {
        return http.get(`${END_POINT}/${userId}`);
    }

    addGallery(gallery: any) {
        return http.post(`${END_POINT}`, gallery);
    }

    updateGallery(galleryId: string, gallery: any) {
        return http.put(`${END_POINT}/${galleryId}`, gallery);
    }
}

export default new GalleriesService();
