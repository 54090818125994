import http from "../http-common";

const END_POINT: string = "elements";

class ElementsService {

    getElements() {
        return http.get(END_POINT)
    }

    addElement(element: any) {
        return http.post(END_POINT, element)
    }

    updateElement(element: any) {
        const { id, ...updateElement } = element

        return http.put(END_POINT + '/' + id, updateElement)
    }
}

export default new ElementsService();
