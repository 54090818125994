import vuexErrorHandler from "@/helpers/vuexErrorHandler";
import CardsService from "@/services/CardsService";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
class CardsModule extends VuexModule {
    public cards: any[] = [];

    @Mutation
    public SET_CARDS(cards: any[]): void {
        this.cards = cards;
    }

    @Action
    public async getUserCards(userId: string): Promise<void> {
        try {
            const response = await CardsService.getUserCards(userId);
            const { cards } = response.data;

            this.context.commit("SET_CARDS", cards);
            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async addCard(card: any) {
        try {
            const response = await CardsService.addCard(card)
            const notification = {
                message: `Rekening <strong>${card.bank_name}</strong> berhasil ditambahkan.`,
            };
            this.context.dispatch("meta/addNotification", notification, {
                root: true,
            });
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async updateCard(card: any) {
        try {
            const response = await CardsService.updateCard(card)
            const notification = {
                message: `Rekening <strong>${card.bank_name}</strong> berhasil diedit.`,
            };
            this.context.dispatch("meta/addNotification", notification, {
                root: true,
            });
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async deleteCard(card: any) {
        try {
            const response = await CardsService.deleteCard(card)
            const notification = {
                message: `Rekening <strong>${card.bank_name}</strong> berhasil dihapus.`,
            };
            this.context.dispatch("meta/addNotification", notification, {
                root: true,
            });
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }
}

export default CardsModule;
