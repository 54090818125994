import vuexErrorHandler from "@/helpers/vuexErrorHandler";
import NotificationsService from "@/services/NotificationsService";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
class NotificationsModule extends VuexModule {
    public notifications: any[] = [];

    get sortedNotifications() {
        const sortedNotifications: any[] = this.notifications.sort(
            (a: any, b: any) => {
                return b.created_at.localeCompare(a.created_at);
            }
        );

        return sortedNotifications;
    }

    get unreadedNotifications() {
        const unreadedNotifications: any[] = this.sortedNotifications.filter(
            (notification: any) => {
                return !notification.is_readed;
            }
        );

        return unreadedNotifications;
    }

    @Mutation
    public SET_NOTIFICATIONS(notifications: any[]): void {
        this.notifications = notifications;
    }

    @Action
    public async getAllNotification(): Promise<any> {
        try {
            const response = await NotificationsService.getAllNotification();
            
            const { notifications } = response.data;

            this.context.commit("SET_NOTIFICATIONS", notifications);
            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async addNotification(payload: any): Promise<any> {
        const { description, route, receiverId } = payload;

        try {
            const response = await NotificationsService.addNotification(
                payload
            );
            const { notification } = response.data;

            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async readNotification(notificationId: string): Promise<any> {
        try {
            const response = await NotificationsService.readNotification(
                notificationId
            );

            await this.context.dispatch("getAllNotification");

            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }
}

export default NotificationsModule;
