import http from "../http-common";

const END_POINT: string = "/plans";

class PlansService {
    addPlan(body: any) {
        const { year, fund, events, interests } = body;
        return http.post(`${END_POINT}`, body);
    }

    updatePlan(body: any) {
        const { id, year, fund, events, interests } = body;
        return http.put(`${END_POINT}/${id}`, body);
    }

    getAllPlan() {
        return http.get(`${END_POINT}`);
    }

    getCurrentYearPlans(year: string | number) {
        return http.get(`${END_POINT}?year=${year}`);
    }

    getOnePlan(planId: string) {
        return http.get(`${END_POINT}/${planId}`);
    }

    getDashboardPlan(userId: string, year: number) {
        return http.get(`${END_POINT}?user=${userId}&year=${year}`);
    }

    getUserPlans(userId: string) {
        return http.get(`users/${userId}${END_POINT}`)
    }
}

export default new PlansService();
