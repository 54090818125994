import vuexErrorHandler from "@/helpers/vuexErrorHandler";
import StoriesService from "@/services/StoriesService";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
class StoriesModule extends VuexModule {
    public stories: any[] = [];

    @Mutation
    public SET_STORIES(stories: any[]): void {
        this.stories = stories;
    }

    @Action
    public async getProposalStories(proposalId: string): Promise<void> {
        try {
            const response = await StoriesService.getProposalStories(proposalId);
            const { stories } = response.data;

            this.context.commit("SET_STORIES", stories);
            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async addStory(story: any) {
        try {
            let formData = new FormData();

            for (const key in story) {
                if (story[key])
                    formData.append(key, story[key].id || story[key]);
            }

            const response = await StoriesService.addStory(formData)
            const notification = {
                message: `Story <strong>${story.caption}</strong> berhasil ditambahkan.`,
            };
            this.context.dispatch("meta/addNotification", notification, {
                root: true,
            });
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async updateStory(story: any) {
        try {
            const response = await StoriesService.updateStory(story)
            const notification = {
                message: `Rekening <strong>${story.caption}</strong> berhasil diedit.`,
            };
            this.context.dispatch("meta/addNotification", notification, {
                root: true,
            });
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async deleteStory(storyId: any) {
        try {
            const response = await StoriesService.deleteStory(storyId)
            const notification = {
                message: `Story berhasil dihapus.`,
            };
            this.context.dispatch("meta/addNotification", notification, {
                root: true,
            });
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }
}

export default StoriesModule;
