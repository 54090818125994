import http from "../http-common";

const END_POINT: string = "cards";

class CardsService {
    getUserCards(userId: string) {
        return http.get('users/' + userId + '/cards')
    }

    addCard(card: any) {
        return http.post(END_POINT, card)
    }

    updateCard(card: any) {
        const { id, ...updateCard } = card

        return http.put(END_POINT + '/' + id, updateCard)
    }

    deleteCard(card: any) {
        return http.delete(END_POINT + '/' + card.id)
    }
}

export default new CardsService();
