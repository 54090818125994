import vuexErrorHandler from "@/helpers/vuexErrorHandler";
import StatisticsService from "@/services/StatisticsService";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
class StatisticsModule extends VuexModule {
    public companyStatistics: any = null;
    public landingStatistics: any = {
        proposersCount: 0,
        companiesCount: 0,
        proposalsCount: 0,
        fundsTotal: 0
    };
    public adminDashboard: any = null;

    @Mutation
    public SET_COMPANY_STATISTICS(statistics: any): void {
        this.companyStatistics = statistics;
    }

    @Mutation
    public SET_LANDING_STATISTICS(statistics: any): void {
        this.landingStatistics = statistics;
    }

    @Mutation
    public SET_ADMIN_DASHBOARD(dashboard: any): void {
        this.adminDashboard = dashboard;
    }

    @Action
    public async getCompanyStatistics(payload: any): Promise<any> {
        const { userId, year } = payload;

        try {
            const response = await StatisticsService.getCompanyStatistics(
                userId,
                year
            );
            const { statistics } = response.data;

            this.context.commit("SET_COMPANY_STATISTICS", statistics);
            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async getAdminDashboard(year?: number): Promise<any> {

        try {
            const response = await StatisticsService.getAdminDashboard(
                year
            );
            const { dashboard } = response.data;

            if (!dashboard) return { errors: [{ id: '', message: 'Something wrong' }] }

            this.context.commit("SET_ADMIN_DASHBOARD", dashboard);
            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    

    @Action
    public async getLandingStatistics(): Promise<any> {

        try {
            const response = await StatisticsService.getLandingStatistics()
            const { statistics } = response.data

            if (!statistics) return { errors: [{ id: '', message: 'Something wrong' }] }

            this.context.commit("SET_LANDING_STATISTICS", statistics);
            return response.data;

        } catch (err) {
            console.log(err);

            if (err && err.response)
                this.context.dispatch("meta/errorHandler", err.response, {
                    root: true,
                });
        }
        
        // try {
        //     const response = await StatisticsService.getAdminDashboard(
        //         year
        //     );
        //     const { dashboard } = response.data;

        //     if (!dashboard) return { errors: [{ id: '', message: 'Something wrong' }] }

        //     this.context.commit("SET_ADMIN_DASHBOARD", dashboard);
        //     return response.data;
        // } catch (err) {
        //     console.log(err.response);

        //     this.context.dispatch("meta/errorHandler", err.response, {
        //         root: true,
        //     });

        //     return err.response.data;
        // }
    }
}

export default StatisticsModule;
