import http from "../http-common";

const END_POINT: string = "/notifications";

class NotificationsService {
    addNotification(body: any) {
        return http.post(`${END_POINT}`, body);
    }

    getAllNotification() {
        return http.get(`${END_POINT}`);
    }

    readNotification(notificationId: string) {
        return http.put(`${END_POINT}/${notificationId}/read`);
    }
}

export default new NotificationsService();
