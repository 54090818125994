import RolesService from "@/services/RolesService";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
class RolesModule extends VuexModule {
    public roles: any[] = [];

    get sortedRoles() {
        let sortedRoles: any = this.roles.sort((a: any, b: any) => {
            return a.name.localeCompare(b.name);
        });

        return sortedRoles;
    }

    @Mutation
    public SET_ROLES(roles: any[]): void {
        this.roles = roles;
    }

    @Action
    public async getAllRole(): Promise<any> {
        try {
            const response = await RolesService.getAllRole();
            const { roles } = response.data;

            this.context.commit("SET_ROLES", roles);
            return response.data;
        } catch (err) {
            console.log(err.response);
            return err.response.data;
        }
    }

    // @Action
    // public async addRole(roleName: string): Promise<any> {
    //     console.log(roleName);

    //     try {
    //         const response = await RolesService.addRole(roleName);
    //         const { role } = response.data;

    //         const updatedRoles: any[] = [...this.roles];
    //         updatedRoles.push(role);
    //         this.context.commit("SET_ROLES", updatedRoles);

    //         return response.data;
    //     } catch (err) {
    //         console.log(err.response);
    //         return err.response.data;
    //     }
    // }
}

export default RolesModule;
