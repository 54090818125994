import http from "../http-common";
import { UsernameAndPassword } from "@/interfaces/UserInterface";

const END_POINT: string = "/users";

class UserService {
  // signUpProposerWithUsernameAndPassword(username: string, password: string) {
  //     let body: any = { username, password, rolename: "PROPOSER" };

  //     return http.post(`users`, body);
  // }

  signInWithSso(ssoId: number) {
    return http.post(`auth/sso/${ssoId}`);
  }

  signInWithUsernameAndPassword(options: UsernameAndPassword) {
    // return http.post(`auth/login`, options);
    return http.post(`auth/login`, options);
  }

  signUpWithUsernameAndPassword(options: any) {
    return http.post(`${END_POINT}`, options);
  }

  // resetPassword(userId: string) {
  //     let body: any = { id: userId };

  //     return http.post(`${END_POINT}/reset-password`, body);
  // }

  getAllUser() {
    return http.get(`${END_POINT}`);
  }

  getAllAdmin() {
    return http.get(`${END_POINT}?role=ADMIN`);
  }

  getUserById(userId: string) {
    return http.get(`${END_POINT}/${userId}`);
  }

  signOut() {
    return http.post(`auth/logout`);
  }

  addProfile(profile: any) {
    return http.post(`profiles`, profile);
  }

  updateProfile(profile: any) {
    return http.put(`profiles`, profile);
  }

  lockUser(userId: string, lock_reason: string) {
    const body: any = { lock_reason };

    return http.put(`${END_POINT}/${userId}/lock`, body);
  }

  unlockUser(userId: string) {
    return http.put(`${END_POINT}/${userId}/unlock`);
  }

  deleteUser(userId: string) {
    return http.delete(`${END_POINT}/${userId}`);
  }

  setFcmToken(userId: string, token: string) {
    const body: any = { fcm_token: token };

    return http.put(`${END_POINT}/${userId}/token`, body);
  }

  sendMessage(userId: string, message: any) {
    const body = {
      title: message.title,
      body: message.body,
      click_action: message.click_action,
    };
    return http.post(`messages/${userId}`, body);
  }

  getUserFund(userId: string, year: string) {
    return http.get(`${END_POINT}/${userId}/plan/${year}`);
  }

  changePassword(userId: string, payload: any) {
    const { oldPassword, newPassword, confirmNewPassword } = payload;

    return http.put(`${END_POINT}/${userId}/password`, payload)
  }

  resetPassword(userId: string) {
    return http.post(`${END_POINT}/${userId}/reset-password`,)
  }

  bindSso(ssoId: string) {
    return http.post(`sso/${ssoId}/bind`,)
  }
}

export default new UserService();
