import store from "@/store";

const vuexErrorHandler = (error: any) => {
    console.error('--------------------');
    console.error(error);
    
    const hasResponse = error && error.response
    if (hasResponse) {
        store.dispatch("meta/errorHandler", error.response)

        return error.response.data;
    }    

    return error

}

export default vuexErrorHandler