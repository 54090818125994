import TypesService from "@/services/TypesService";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
class TypesModule extends VuexModule {
    public types: any[] = [];

    get sortedTypes() {
        let sortedTypes: any = this.types.sort((a: any, b: any) => {
            return a.name.localeCompare(b.name);
        });

        return sortedTypes;
    }

    @Mutation
    public SET_TYPES(types: any[]): void {
        this.types = types;
    }

    @Action
    public async getAllTypes(): Promise<any> {
        try {
            const response = await TypesService.getAllTypes();
            const { types } = response.data;

            this.context.commit("SET_TYPES", types);
            return response.data;
        } catch (err) {
            console.log(err.response);
            return err.response.data;
        }
    }

    @Action
    public async addType(typeName: string): Promise<any> {
        console.log(typeName);

        try {
            const response = await TypesService.addType(typeName);
            const { type } = response.data;

            const updatedTypes: any[] = [...this.types];
            updatedTypes.push(type);
            this.context.commit("SET_TYPES", updatedTypes);

            return response.data;
        } catch (err) {
            console.log(err.response);
            return err.response.data;
        }
    }
}

export default TypesModule;
