import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#5D4AFF",
                info: "#00DAFD",
                success: "#53F293",
                error: "#FF5042",
                errorBg: "#FBE9E7",
                warning: "#FFAA00",
                navbar: "#FFFFFF",
                drawer: "#242939",
                drawerActive: "#0F1523",
                primaryActive: "#e7f3ff",
                // base: "#F9F9F9",
                base: "#F0F2F5",
                input: "#EAEAEA",
                button: "#E4E6EB",
                gradientStart: "#4F01FD",
                gradientEnd: "#A0B1FF",
            },
            dark: {
                //
            },
        },
    },
});
