import http from "../http-common";

const END_POINT: string = "sponsors";

class SponsorsService {

    getSponsors() {
        return http.get(END_POINT)
    }

    addSponsor(sponsor: any) {
        return http.post(END_POINT, sponsor)
    }

    updateSponsor(sponsorId: string, sponsor: any) {

        return http.put(END_POINT + '/' + sponsorId, sponsor)
    }

    deleteSponsor(sponsorId: string) {
        return http.delete(END_POINT + '/' + sponsorId)
    }
}

export default new SponsorsService();
