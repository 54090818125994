





































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class OthersNotificationListItem extends Vue {
    @Prop() notification!: any;

    public showNotification: boolean = true;
    public timeout: number = 4000;
    public color: string = this.isMobile ? "" : "primary";

    get isMobile() {
        return this.$vuetify.breakpoint.xs;
    }

    mounted() {
        const hasTimeout = this.notification && this.notification.timeout;
        const hasColor = this.notification && this.notification.color;

        if (hasTimeout) this.timeout = this.notification.timeout;

        if (hasColor) this.color = this.notification.color;
    }

    @Watch("showNotification")
    removeNotification(showNotification: boolean) {
        if (!showNotification)
            this.$store.dispatch(
                "meta/removeNotification",
                this.notification.id
            );
    }
}
