import { removeItem } from './../../helpers/arrayHelper';
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Vue } from "vue-property-decorator";

@Module({ namespaced: true })
class MetaModule extends VuexModule {
    public pageTransition: any = {
        name: "router-view",
        mode: "in-out",
    };

    public notifications: any[] = [];

    @Mutation
    public SET_PAGE_TRANSITION(value: string): void {
        if ("default" === value) {
            Vue.set(this.pageTransition, "name", "router-view");
            Vue.set(this.pageTransition, "mode", "in-out");
        }
        if ("back" === value) {
            Vue.set(this.pageTransition, "name", "router-view-back");
            Vue.set(this.pageTransition, "mode", "");
        }
    }

    @Mutation
    public ADD_NOTIFICATION(notification: any): void {
        this.notifications.push({
            id:
                Math.random().toString(36) +
                Date.now()
                    .toString(36)
                    .substr(20),
            ...notification,
        });
    }

    @Mutation
    public REMOVE_NOTIFICATION(notificationId: string): void {
        const updatedNotifications = removeItem([...this.notifications], notificationId)
        
        this.notifications = [...updatedNotifications]
    }

    @Action
    public addNotification(notification: any): void {
        this.context.commit("ADD_NOTIFICATION", notification);
    }

    @Action
    public removeNotification(notificationId: any): void {
        this.context.commit("REMOVE_NOTIFICATION", notificationId);
    }

    @Action
    public errorHandler(response: any): void {
        const hasError: boolean = response.data && response.data.errors;

        if (!hasError) return;

        let message: string = `Terjadi kesalahan`;
        let arrayMessage: string[] = [];
        const errors: any[] = response.data.errors;

        errors.map((error) => {
            arrayMessage.push(
                `[<strong>${error.field}</strong>] <strong>${error.message}</strong>`
            );
        });

        const notification: any = {
            message,
            arrayMessage,
            color: "error",
            timeout: -1,
        };

        this.context.dispatch("addNotification", notification);
    }
}

export default MetaModule;
