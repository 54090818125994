import http from "../http-common";

const END_POINT: string = "/roles";

class RolesService {
    // addCategory(categoryName: string) {
    //     let body: any = { name: categoryName };

    //     return http.post(`${END_POINT}`, body);
    // }

    getAllRole() {
        return http.get(`${END_POINT}`);
    }
}

export default new RolesService();
