import moment from "moment";

export const toCurrency = (number: number, usePrefix?: boolean) => {
    const convertedValue = Number(number)
        .toLocaleString("id-ID", {
            style: "currency",
            currency: "IDR",
        })

    if (usePrefix === false) return convertedValue.substring(3).slice(0, -3)
    
    return convertedValue.slice(0, -3);
};

export const toNumber = (value: any) => {
    let valueToNumber: any = value;
    if (typeof value === "string")
        valueToNumber = Number(valueToNumber.replace(/\./g, ""));

    return valueToNumber;
};

export const toDisplayName = (user: any) => {
    if (!user) return ""
    
    let displayName = user.username;
    const hasProfile = user && user.profile;
  
    if (hasProfile) displayName = user.profile.display_name;
  
    return displayName;
};

export const toDate = (date: any) => {
    const options = "DD MMMM yyyy";

    let formattedDate = null

    if (date.seconds) {
        formattedDate = String(moment(date.seconds * 1000).format(options));
    } else {
        formattedDate = String(moment(date).format(options));
    }

    return formattedDate
}


export const toDateAndTime = (date: any) => {
    const options = "DD MMMM yyyy, hh:mm";

    let formattedDate = null

    if (date.seconds) {
        formattedDate = String(moment(date.seconds * 1000).format(options));
    } else {
        formattedDate = String(moment(date).format(options));
    }

    return formattedDate
}
