import { updateItem } from "@/helpers/arrayHelper";
import vuexErrorHandler from "@/helpers/vuexErrorHandler";
import ElementsService from "@/services/ElementsService";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
class ElementsModule extends VuexModule {
    public elements: any[] = [];

    @Mutation
    public SET_ELEMENTS(elements: any[]): void {
        this.elements = elements;
    }

    @Action
    public async getElements(userId: string): Promise<void> {
        try {
            const response = await ElementsService.getElements();
            const { elements } = response.data;
            
            this.context.commit("SET_ELEMENTS", elements);
            
            return response.data;
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async addElement(element: any) {
        try {
            const response = await ElementsService.addElement(element)
            const notification = {
                message: `Element <strong>${element.name}</strong> berhasil ditambahkan.`,
            };
            this.context.dispatch("meta/addNotification", notification, {
                root: true,
            });

            this.context.commit("SET_ELEMENTS", [response.data.element, ...this.elements]);

            return response
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }

    @Action
    public async updateElement(element: any) {
        try {
            const response = await ElementsService.updateElement(element)
            const updatedElements = updateItem(this.elements, response.data.element)
            this.context.commit('SET_ELEMENTS', updatedElements)
            
            const notification = {
                message: `Element <strong>${element.name}</strong> berhasil diedit.`,
            };
            this.context.dispatch("meta/addNotification", notification, {
                root: true,
            });
        } catch (err) {
            return vuexErrorHandler(err)
        }
    }
}

export default ElementsModule;
