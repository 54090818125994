import { useQuery } from "@/helpers/useQuery";
import http from "../http-common";

const END_POINT: string = "/proposals";

class ProposalService {
    findAll(options: any) {
        const query = useQuery(options)

        return http.get(END_POINT + query);
    }

    addProposal(proposal: any) {
        return http.post(`${END_POINT}`, proposal);
    }

    getAllProposalApplication() {
        // return http.get(`${END_POINT}`);
        return http.get(`${END_POINT}?tipe=APLIKASI`);
    }

    getOneProposal(proposalId: string) {
        return http.get(`${END_POINT}/${proposalId}`);
    }

    addFund(proposalId: string, givenFund: number) {
        const body = { nominal: givenFund };

        return http.post(`funds/${proposalId}`, body);
    }

    addSeen(proposalId: string) {
        return http.post(`seens/${proposalId}`);
    }
}

export default new ProposalService();
