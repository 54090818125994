import http from "../http-common";

const END_POINT: string = "reports";

class ReportsService {
    getProposalReports(proposalId: string) {
        return http.get(`proposals/${proposalId}/reports`,)
    }

    addReport(proposalId: string, report: any) {
        return http.post(`proposals/${proposalId}/reports`, report)
    }

    deleteReport(reportId: string) {
        return http.delete(END_POINT + '/' + reportId)
    }
}

export default new ReportsService();
