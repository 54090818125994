import axios from "axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
import {
    PRODUCTION_MODE,
    COOKIE_NAME,
    API_END_POINT,
    SERVER_HOST,
    SERVER_PORT,
} from "@/constants";

// console.log(config);


Vue.use(VueCookies);

const accessToken = Vue.$cookies.get(COOKIE_NAME);

const host = window.location.hostname;
const baseUrlDev = `http://${SERVER_HOST}:${SERVER_PORT}/${API_END_POINT}`
// const baseUrlProd = `https://${SERVER_HOST}/${API_END_POINT}`
const baseUrlProd = `https://${SERVER_HOST}/`
// console.log('API Url: ', PRODUCTION_MODE ? baseUrlProd : baseUrlDev);

axios.defaults.headers["auth"] = accessToken;

export default axios.create({
    baseURL: PRODUCTION_MODE ? baseUrlProd : baseUrlDev,
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
});
