import { toDisplayName } from './../../helpers/Formatter';
import { decodeToken } from './../../helpers/DecodeToken';
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import UsersService from "@/services/UsersService";
import { UsernameAndPassword } from "@/interfaces/UserInterface";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
import router from "@/router";

import {
  COOKIE_NAME,
  SERVER_HOST,
  WEB_PORT,
  SITE_URL
} from "@/constants";
import httpCommon from '@/http-common';
import vuexErrorHandler from '@/helpers/vuexErrorHandler';

@Module({ namespaced: true })
class Users extends VuexModule {
  public me: any = null;
  public user: any = null;
  public users: any[] = [];
  public adminUsers: any[] = [];
  public userPlan: any = null;
  public currentToken: any = null

  get isLoggedIn(): boolean {
    return !!this.me
  }

  get meRole(): string | null {
    if (!this.me) return null;
    let role: string = this.me.role.name;

    return role;
  }

  get sortedUsers() {
    let sortedUsers: any[] = this.users.sort((a: any, b: any) => {
      return b.created_at.localeCompare(a.created_at);
    });

    return sortedUsers;
  }

  @Mutation
  public SET_ME(me: any): void {
    this.me = me;
  }

  @Mutation
  public SET_USERS(users: any[]): void {
    this.users = users;
  }

  @Mutation
  public SET_ADMIN_USERS(users: any[]): void {
    this.adminUsers = users;
  }

  @Mutation
  public SET_USER(user: any): void {
    this.user = user;
  }

  @Mutation
  public CLEAR_ME(me: any): void {
    this.me = null;
  }

  @Mutation
  public SET_USER_PLAN(userPlann: any): void {
    this.userPlan = userPlann;
  }

  @Mutation
  public SET_CURRENT_TOKEN(token: any): void {
    this.currentToken = token;
  }

  @Action
  public async getMe(userId: string): Promise<void> {
    try {
      const response = await UsersService.getUserById(userId);
      const me = response.data.user;
      this.context.commit("SET_ME", me);

      // const profileRoute = '/app/pengaturan'
      // const isOnProfileRoute = router.currentRoute.path === profileRoute

      // if (!me.profile && !isOnProfileRoute) router.push("/app/pengaturan");

      return me
    } catch (err: any) {
      return vuexErrorHandler(err)
    }
  }

  @Action
  public async getAllUser(): Promise<void> {
    try {
      const response = await UsersService.getAllUser();

      const users = response.data.users;
      this.context.commit("SET_USERS", users);

      return users;
    } catch (err: any) {
      console.log(err.response);
    }
  }

  @Action
  public async getAllAdmin(): Promise<void> {
    try {
      const response = await UsersService.getAllAdmin();

      const users = response.data.users;
      this.context.commit("SET_ADMIN_USERS", users);

      return users;
    } catch (err: any) {
      console.log(err.response);
    }
  }

  @Action
  public async getOneUser(userId: string): Promise<void> {
    try {
      const response = await UsersService.getUserById(userId);

      const user = response.data.user;
      this.context.commit("SET_USER", user);

      return user;
    } catch (err: any) {
      console.log(err.response);
    }
  }

  @Action
  public async onSuccessSignIn(auth: any) {
      const accessToken = auth.token;
      const userId = auth.userId;

      Vue.$cookies.set(COOKIE_NAME, accessToken);
      await this.context.dispatch("getMe", userId);
      httpCommon.defaults.headers["auth"] = accessToken;
      this.context.dispatch("notifications/getAllNotification", null, { root: true });
  }

  @Action
  public async signInWithSso(ssoId: number) {
      try {
          const response: any = await UsersService
            .signInWithSso(ssoId)
            .catch(error => null)

          if (!response) return null

          this.context.dispatch('onSuccessSignIn', response.data.auth)

          return response.data.auth
          
      } catch (err: any) {
          // this.context.dispatch("signOut");
          return vuexErrorHandler(err)
      }
  }

  @Action
  public async bindSso(ssoId: string) {
      try {
          const response = await UsersService.bindSso(ssoId).catch(() => {
            return false
          });

          return !!response
          
      } catch (err: any) {
          // this.context.dispatch("signOut");
          return vuexErrorHandler(err)
      }
  }

  @Action
  public async signInWithUsernameAndPassword(options: UsernameAndPassword) {
      try {
          const response = await UsersService.signInWithUsernameAndPassword(
            options
          );
          const accessToken = response.data.auth.token;
          const userId = response.data.auth.userId;

          Vue.$cookies.set(COOKIE_NAME, accessToken);
          await this.context.dispatch("getMe", userId);
          httpCommon.defaults.headers["auth"] = accessToken;
          this.context.dispatch("notifications/getAllNotification", null, { root: true });

          return response.data.auth
          
      } catch (err: any) {
          // this.context.dispatch("signOut");
          return vuexErrorHandler(err)
      }
  }

  @Action
  public async signUpWithUsernameAndPassword(payload: any) {
    const { username, password, role } = payload;
    try {
      const response = await UsersService.signUpWithUsernameAndPassword(
        payload
      );

      if (role.name === "PROPOSER") {
        const options: UsernameAndPassword = { username, password };
        await this.context.dispatch("signInWithUsernameAndPassword", options);

        router.push("/app/pengaturan");
      }

      return response.data;
    } catch (err: any) {
      // this.context.dispatch("signOut");

      const errors = err.response.data.errors;

      return errors;
    }
  }

  @Action
  public async signOut() {
    await UsersService.signOut();

    Vue.$cookies.remove(COOKIE_NAME);
    this.context.commit("CLEAR_ME");

    router.push("/");
  }

  @Action
  public async addProfile(profile: any) {
    let formData = new FormData();

    for (const key in profile) {
      if (profile[key]) formData.append(key, profile[key].id || profile[key]);
    }

    try {
      const response = await UsersService.addProfile(formData);
      const userId = response.data.profile.user;

      this.context.dispatch("getMe", userId);
      const me = this.me
      const userRole = this.me.role.name

      // send Notification to admin
      const { users } = response.data;
      
      

      if (userRole === 'PROPOSER') {
        users.map(async (user: any) => {
          // description, route, receiverId
          const description: string = `<strong>${profile.display_name}</strong> mendaftar sebagai <strong>PROPOSER</strong>`;
          const route: string = "/app/pengusul/" + userId;
          const receiverId: string = user.id;
          const notification: any = { description, route, receiverId };

          await this.context.dispatch(
            "notifications/addNotification",
            notification,
            { root: true }
          );

          const message = {
            title: `Ada pendaftar baru`,
            body: `${toDisplayName(me)} mendaftar sebagai pengusul`,
            click_action:
                "https://ecsr.banjarmasinkota.go.id/app/pengusul/" +
                me.id,
        };
          const pushMessage = { userId: user.id, message };

          await this.context.dispatch("users/sendMessage", pushMessage, {
            root: true,
        });
        })
      }

      const notification = {
        message: `Profil diperbaharui.`,
      };
      this.context.dispatch("meta/addNotification", notification, {
        root: true,
      });

      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }

  @Action
  public async updateProfile(profile: any) {
    let formData = new FormData();

    for (const key in profile) {
      if (profile[key]) formData.append(key, profile[key].id || profile[key]);
    }

    // for (var pair of formData.entries()) {
    //     console.log(pair[0] + ", " + pair[1]);
    // }

    try {
      const response = await UsersService.updateProfile(formData);
      const userId = response.data.profile.user;

      this.context.dispatch("getMe", userId);

      const notification = {
        message: `Profil diperbaharui.`,
      };
      this.context.dispatch("meta/addNotification", notification, {
        root: true,
      });

      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }

  @Action
  public async lockUser(payload: any) {
    const { user, lock_reason } = payload;

    try {
      const response = await UsersService.lockUser(user.id, lock_reason);
      
      const description: string = `Akun anda sementara dikunci oleh <strong>Admin</strong>, karena <strong>${lock_reason}</strong>`;
      const route: string = "/pengusul/" + user.id;
      const receiverId: string = user.id;
      const notification: any = { description, route, receiverId };

      await this.context.dispatch(
        "notifications/addNotification",
        notification,
        { root: true }
      );

      const pushNotification = {
        message: `User <strong>${toDisplayName(user)}</strong> dikunci.`,
      };      

      await this.context.dispatch(
        "meta/addNotification",
        pushNotification,
        { root: true }
      );

      const userId = user.id;
      const message = {
        title: "Akun anda sementara dikunci oleh Admin",
        body: "karena " + lock_reason,
        click_action: `https://${SITE_URL}/app`,
      };

      await this.context.dispatch("sendMessage", { userId, message });

      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }

  @Action
  public async unlockUser(user: any) {
    try {
      const response = await UsersService.unlockUser(user.id);
      const description: string = `Akun anda telah diaktifkan kembali oleh <strong>Admin</strong>`;
      const route: string = "/pengusul/" + user.id;
      const receiverId: string = user.id;
      const notification: any = { description, route, receiverId };

      await this.context.dispatch(
        "notifications/addNotification",
        notification,
        { root: true }
      );

      const pushNotification = {
        message: `User <strong>${toDisplayName(user)}</strong> diaktifkan.`,
      };
      this.context.dispatch("meta/addNotification", pushNotification, {
        root: true,
      });

      const userId = user.id;
      const message = {
        title: "Akun anda telah diaktifkan kembali Admin",
        body: "anda sekarang dapat mengupload proposal lagi",
        click_action: `https://${SITE_URL}/app`,
      };

      await this.context.dispatch("sendMessage", { userId, message });

      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }

  @Action
  public async deleteUser(user: any) {
    try {
      const response = await UsersService.deleteUser(user.id);

      const pushNotification = {
        message: `User <strong>${toDisplayName(user)}</strong> telah dihapus.`,
        color: "error",
      };
      this.context.dispatch("meta/addNotification", pushNotification, {
        root: true,
      });

      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }

  @Action
  public async setFcmToken(token: string) {
    try {
      const userId = this.me.id;
      const response = await UsersService.setFcmToken(userId, token);
      if (response && response.status === 200) this.context.commit('SET_CURRENT_TOKEN', token)

    } catch (err: any) {
      console.error(err);

      this.context.dispatch("meta/errorHandler", err.response, {
        root: true,
      });

      return err.response.data;
    }
  }

  @Action
  public async sendMessage(payload: any) {
    const { userId, message } = payload;

    try {
      const response = await UsersService.sendMessage(userId, message);

      // console.log(response);
    } catch (err: any) {
      console.error(err);

      this.context.dispatch("meta/errorHandler", err.response, {
        root: true,
      });

      return err.response.data;
    }
  }

  @Action
  public async getUserFund(payload: any): Promise<void> {
    const { userId, year } = payload;

    try {
      const response = await UsersService.getUserFund(userId, year);
      const userPlan = response.data.plan;
      this.context.commit("SET_USER_PLAN", userPlan);
    } catch (err: any) {
      console.log(err.response);
    }
  }

  @Action
  public async changePassword(payload: any): Promise<any> {
        const { oldPassword, newPassword, confirmNewPassword } = payload;
        const userId = this.me.id
        

        try {
            const response = await UsersService.changePassword(userId, payload)

            const pushNotification = {
                message: `Password berhasil diganti.`,
            };
            this.context.dispatch("meta/addNotification", pushNotification, {
                root: true,
            });

            return response.data
        } catch (err: any) {
            console.log(err.response.data);
            
            return err.response.data
        }
  }

  @Action
  public async resetPassword(user: any): Promise<any> {     
        try {
            const response = await UsersService.resetPassword(user.id)

            const pushNotification = {
                message: `Password akun <strong>${toDisplayName(user)}</strong> berhasil direset menjadi <strong>12345678</strong>.`,
            };
            this.context.dispatch("meta/addNotification", pushNotification, {
                root: true,
            });

            return response.data
        } catch (err: any) {
            console.log(err.response.data);
            
            return err.response.data
        }
  }
}

export default Users;
